import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

function RedirectPage() {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Call the backend API when the component mounts
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
    const signInWithInstagram = async () => {
      try {
        const response = await axios.post('https://gameapi.kaleyire.com/instagram/signin', {
          code : code
        });
        console.log('API Response:', response.data);
        // Handle the response as needed
        if (response.data) {
          localStorage.setItem('instagram_access_token', response.data);
          navigate('/');
        }

      } catch (error) {
        console.error('Error signing in with Instagram:', error);
        // Handle the error as needed
      }
    };

    signInWithInstagram();
  }, [location.search, navigate]);

  const handleLogout = () => {
    // Perform logout logic here
    navigate('/login');
  };

  return (
    <div className="container mt-5">
      <h2>Welcome</h2>
      <p>You have successfully logged in!</p>
      <button className="btn btn-primary" onClick={handleLogout}>Logout</button>
    </div>
  );
}

export default RedirectPage;
