import React, { useEffect, useState } from 'react';
import '../App.css';
import axios from 'axios';
import '../css/HomePage.css';

function HomePage() {
    const [profilePicture, setProfilePicture] = useState('');

    useEffect(() => {
        // Retrieve the access token from local storage
        const accessToken = localStorage.getItem('instagram_access_token');

        // Function to get Instagram profile picture
        const getInstagramProfilePicture = async (token) => {
            try {
                const response = await axios.get(`https://graph.instagram.com/me`, {
                    params: {
                        fields: 'id,username,profile_picture_url',
                        access_token: token,
                    },
                });
                setProfilePicture(response.data.profile_picture_url);
            } catch (error) {
                console.error('Error fetching Instagram profile picture:', error);
                // handleLogout();
            }
        };

        if (accessToken) {
            getInstagramProfilePicture(accessToken);
        } else {
            // handleLogout();
        }
    }, []);


    const handleLogout = () => {
        // Clear the access token from local storage
        localStorage.removeItem('instagram_access_token');
        // Perform logout logic here
        window.location = '/login';
    };

    return (
        <div className="container mt-5">
            <h2>Welcome</h2>
            {profilePicture && <img src={profilePicture} alt="Instagram Profile" className="img-thumbnail" />}
            <p>You have successfully logged in!</p>
            <button className="btn btn-primary" onClick={handleLogout}>Logout</button>
        </div>
    );
}

export default HomePage;
