import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../css/LoginPage.css';

function LoginPage() {

  const handleSubmit = (e) => {
    e.preventDefault();
    window.location = 'https://api.instagram.com/oauth/authorize?client_id=325076363956239&scope=user_profile&response_type=code&redirect_uri=https://game.kaleyire.com/redirect';
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>DaMemeGame</h2>
        <div>
          <button type="button" className="btn btn-outline-primary w-100" onClick={handleSubmit}>
            Login with Instagram
          </button>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
